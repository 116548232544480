<template>
  <div id="cont-videocall">
    <div v-if="!loaded">
      Loading chat data
    </div>
    <div v-else-if="loaded && error">
      <h3>There was an error loading event #{{this.eventId}} please reload and try again</h3>
    </div>
    <div v-else-if="loaded && !connected">
      <h3>Connecting to video chat</h3>
    </div>
    <div id="video">
      <div id="subscriber" v-if="streams.length > 0">
        <div class="video" v-for="sub in streams" :key="sub+ 'sub'" :ref="sub">
        </div>
      </div>
      <div id="subscriber" v-else>
        <div class="video">
          Waiting for people
        </div>
      </div>
      <div id="publisher" class="radius" ref="pub"></div>
      <div class="buttonContainer">
        <div class="btn-green radius" v-if="streams.length > 0" @click="hangup">Hangup</div>
        <div class="btn-green radius" v-else @click="endCall">End Call</div>
      </div>
    </div>
  </div>
</template>

<script>
const importOt = () => import('@opentok/client');
export default {
  name: 'VideoChat',
  props: ['eventId'],
  computed: {
    employee() {
      return this.$store.state.auth.type === 'Employee';
    },
  },
  data() {
    return {
      loaded: false,
      connected: false,
      error: false,
      sessionId: false,
      token: false,
      session: {},
      streams: [],
    };
  },
  mounted() {
    this.loadToken().then((success) => {
      if (success) {
        this.initializeVideo(this.sessionId, this.token);
      } else {
        throw new Error('Not in Progress');
      }
    }).catch((e) => {
      console.error(e);
      this.$store.dispatch('errorHandler/throwTimedError', {
        errorMessage: 'Couldn\'t connect to this video call.  Make sure it is currently in Progress',
        time: 2000,
      });
      this.$router.push('/');
    });
  },
  methods: {
    handleError(error) {
      this.error = error;
    },
    loadToken() {
      return new Promise((resolve, reject) => this.$api.get(`communications/getVideoCallToken/${this.eventId}`).then((res) => {
        if (res.data && res.data.token && res.data.inProgress) {
          this.token = res.data.token;
          this.sessionId = res.data.sessionId;
          this.loaded = true;
          resolve(true);
        } else {
          this.handleError(res.data);
          resolve(false);
        }
      }).catch((err) => {
        this.handleError(err);
        reject(err);
      }));
    },
    initializeVideo(sessionId, token) {
      importOt().then((ot) => {
        const OT = ot.default;
        const session = OT.initSession(
          process.env.VUE_APP_Vonage_Key,
          sessionId,
        );

        // Subscribe to a newly created stream
        session.on('streamCreated', (event) => {
          this.streams.push(event.stream.streamId);
          setTimeout(() => {
            session.subscribe(event.stream, this.$refs[event.stream.streamId][0], {
              insertMode: 'replace',
              width: '100%',
              height: '100%',
            }, this.handleError);
          }, 500);
        });

        session.on('streamDestroyed', (event) => {
          const index = this.streams.indexOf(event.stream.streamId);
          this.$delete(this.streams, index);
          session.unsubscribe(event.stream.streamId);
        });

        // Create a publisher
        const publisher = OT.initPublisher(this.$refs.pub, {
          insertMode: 'append',
          width: '100%',
          height: '100%',
          name: this.$store.state.user.name.firstName || 'User',
        }, this.handleError);

        // Connect to the session
        session.connect(token, (error) => {
        // If the connection is successful, initialize a publisher and publish to the session
          if (error) {
            this.handleError(error);
          } else {
            this.connected = true;
            session.publish(publisher, this.handleError);
          }
        });
        this.session = session;
      });
    },

    hangup() {
      this.session.disconnect();
      this.$router.back();
      this.$nextTick(() => this.$store.dispatch('openLastModal'));
    },

    endCall() {
      this.$api.post('communications/endVideoCall', { eventId: this.eventId }).then(() => {
        this.hangup();
      }).catch((e) => {
        if (e.response.status === 402) {
          this.hangup();
        } else {
          throw e;
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";
#cont-videocall{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: $border_gray;
  z-index: 4000;
}
video{
  border-radius: 0 !important;
}
.buttonContainer{
  z-index: 2000;
  position: absolute;
  height: 50px;
  width: 15vw;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .btn-green{
    background-color: $green_color;
    margin-right: 2vh;
    &:hover{
      background-color: #ffffff;
    }
    &.reverse{
      background-color: #ffffff;
      &:hover{
        background-color: $logo_color;
      }
    }

    &:last-child{
      margin-right: 0;
    }
  }
}
#videos {
  position: absolute;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

#subscriber {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  background-color: $border_gray;
  .video{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translateX(-50%) translateY(-50%);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
.video{
  flex: 1;
}

#publisher {
  position: absolute;
  width: 10vw;
  height: 6vw;
  top: 4vh;
  right: 1vh;
  z-index: 100;
  overflow: hidden;
}

.OT_widget-container{
  background-color: transparent;
}
</style>
